

























import {Vue, Component} from 'vue-property-decorator'
import {Group} from '@/entities/Group'
import {Person} from '@/entities/Person'
import draggable from 'vuedraggable'
import GroupService from '@/service/GroupService'
@Component({
    name: 'Groups',
    components: {
        draggable
    }
})
export default class Groups extends Vue{
    groups: Array<Group> | any = [
        {members: []}
    ]

    data: any = {}

    ungrouped: Array<Person> = []

    newPerson = ""

    groupService: GroupService = new GroupService()

    mounted(){
        this.getGroups();
    }

    getGroups(){
        this.groupService.getGroups().then((data) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            this.groups = data.data;
        });
    }

    addGroup(){
        this.groups.push({members: []});
    }

    addPerson(){
        this.groups[0].members.push({name: this.newPerson, group: 0, pickOrder: 0, picked: false});
        this.$set(this, 'newPerson', "");
    }

    save(){
        var g = {
            groups: this.groups
        }
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        this.groupService.postGroups(g);
    }


}
