import axios from 'axios'
import { Group } from '@/entities/Group';

export default class GroupService{
    getGroups() {
        return axios.get('https://b7iwxbud1l.execute-api.us-east-2.amazonaws.com/prod/groups').then((data) => {
            console.log(data);
            return data;
        }).catch((error) => {
            console.error(error);
        });
    }

    postGroups(groups: Array<Group>) {
        axios.put('https://b7iwxbud1l.execute-api.us-east-2.amazonaws.com/prod/groups', groups).then((data) => {
            console.log('saved');
        }).catch((error) => {
            console.error(error);
        });
    }
}