import axios from 'axios'

export default class PickService{
    getPick(name: any){
        return axios.post('https://b7iwxbud1l.execute-api.us-east-2.amazonaws.com/prod/pick', name).then((data) => {
            return data;
        }).catch((error) => {
            console.error(error);
            throw error;
        });
    }
}