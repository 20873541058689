import axios from 'axios'
import { Person } from '@/entities/Person';

export default class OrderService{
    getPeople() {
        return axios.get('https://b7iwxbud1l.execute-api.us-east-2.amazonaws.com/prod/order').then((data) => {
            console.log(data);
            return data;
        }).catch((error) => {
            console.error(error);
        });
    }

    postOrder(people: any) {
        axios.put('https://b7iwxbud1l.execute-api.us-east-2.amazonaws.com/prod/order', people).then((data) => {
            console.log('saved');
        }).catch((error) => {
            console.error(error);
        });
    }
}