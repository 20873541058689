












import { Person } from '@/entities/Person'
import { Vue, Component } from 'vue-property-decorator'
import OrderService from '@/service/OrderService'

@Component({
  name: 'Home',
  components: {

  }
})
export default class Home extends Vue {

  choices: Array<Person> = []

  selected: Person | null = null
  
  orderService: OrderService = new OrderService()

  getPeople(){
    this.orderService.getPeople().then((data) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      this.choices = data.data;
    });
  }

  mounted(){
    this.getPeople();
  }

  select(){
    this.$router.push({ path: 'Pick', query: { pickFor: this.selected?.name }});
  }

}
