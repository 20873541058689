
















import {Vue, Component} from 'vue-property-decorator'
import PickService from '@/service/PickService'
@Component({
    name: 'Pick',
    components: {

    }
})
export default class Pick extends Vue{
    pickFor: string | null | (string | null)[] = null

    pickService: PickService = new PickService()

    picked: string | null = null

    error: string | null = null

    mounted(){
        this.pickFor = this.$route.query.pickFor;
    }

    pick(){
        this.error = null;
        this.picked = null;
        this.pickService.getPick({'name': this.pickFor}).then((data) => {
            this.picked  = data.data;
        }).catch((error) => {
            console.log(error.response.status);
            if(error.response.status == 401){
                this.error = "It is either not your turn to pick, or you have already picked";
            }
            else if (error.response.status == 400){
                if (error.response.data.name == this.pickFor){
                    this.error = "You cannot pick yourself, pick again";
                    this.picked = error.response.data;
                }else{
                    this.error = "You cannot pick someone in your family, pick again";
                    this.picked = error.response.data;
                }
            }
        });
    }

    back(){
        this.$router.push('/');
    }
}
