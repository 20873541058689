















import {Vue, Component} from 'vue-property-decorator'
import draggable from 'vuedraggable'
import {Person} from '@/entities/Person'
import OrderService from '@/service/OrderService'
@Component({
    name: 'Order',
    components: {
        draggable
    }
})
export default class Order extends Vue{
    order: Array<Person> = []

    orderService: OrderService = new OrderService()

    newPerson = ""

    getPeople(){
        this.orderService.getPeople().then((data) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            this.order = data.data;
        })
    }

    addPerson(){
        this.order.push({name: this.newPerson, group: 0, pickOrder: 0, picked: false});
        this.$set(this, 'newPerson', '');
    }

    save(){
        var p = {
            people: this.order
        }
        this.orderService.postOrder(p);
    }

    mounted(){
        this.getPeople();
    }
}
